@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

.main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  vertical-align: center;
  margin: auto;
  width: 100%;
  background-color: transparent;
  z-index: 100;
}
.headerText {
  background-color: transparent;
  margin-right: 1rem;
  z-index: 200;
}
.container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  z-index: 100;
  width: 100%;
  position: fixed;
  top: 0;
}

.logo {
  font-weight: bold;
  font-family: 'Courgette', cursive;
  font-size: 1.5rem;
  color: #354259;
}

.logo-img {
  width: 15rem;
  margin-left: 1rem;
  color: white;
  transition: 0.2s linear;
}
