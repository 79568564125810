.main {
  background-color: #35425963;
  border-radius: 5px;
  height: 10rem;
  width: 80%;
  margin-top: 2rem;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  animation: moveInBottom .5s ease-out;
}

.form {
  display: flex;
  justify-content: space-around;
  font-size: 1rem;
  font-family: inherit;
  cursor: pointer;
  overflow: visible;
}
.radio,
.checkBox {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
}

.checkBox div {
  padding: 0 0 2rem 1rem;
}
.radio div {
  padding: 1rem 0 0 1rem;
}

.form div {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.form div input {
  font-size: 1.3rem;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.224);
  color: white;
  border: none;
  width: 15vw;
  height: 45px;
}

div .passengers{
  padding:0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 12.5vw;
}

.form div  .passengersInput{
  width: 6vw;  
}

.form div .info {
  width: 13vw;
}

form input::placeholder {
  color: white;
}

@keyframes moveInBottom {
  0% {
      opacity: 0;
      transform: translateY(3rem);
  }

  100% {
      opacity: 1;
      transform: translate(0);
  }
}
