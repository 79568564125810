.main {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.travelerInfo {
    width: 70vw;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.infoContainer {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.personalInfo,
.ticketInfo {
  text-align: center;
  margin-bottom: 5rem;
}

.btn {
  display: flex;
  justify-content: center;
}
