.main {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: #ece5c7f4;
}

.bookingForm {
  display: flex;
  flex-direction: column;
  /*  flex-wrap: wrap; */
  justify-content: space-around;
  align-items: center;
}

.bookingForm > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bookingForm form.invis {
  display: none;
}

.bookingForm form.visible {
  display: flex;
}

.bookingForm form > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 2rem;
  margin: 2rem 5rem;
}

.bookingForm button {
  width: 6.5rem;

  text-align: center;
}

.bookingForm input,
.bookingForm select {
  width: 12rem;
  height: 2rem;
  font-size: 1rem;
  border-radius: 5px;
  margin-top: 1rem;
  margin-left: 0.5rem;
  background-color: rgba(255, 255, 255, 0.556);
  border-color: #354259;
  color: black;
  padding: 0.5rem;
}

.bookingForm input:invalid {
  border: 1px solid red;
}

.bookingForm input::placeholder {
  color: #35425979;
  font-weight: bold;
}

.bookingForm label {
  color: #354259;
  font-weight: bold;
}

.AdultContainer,
.childContainer {
  display: flex;
  justify-content: flex-end;

  margin-left: 1rem;
  padding: 1rem;
}

.submitBtn {
  display: flex;
  justify-content: center;
}

.submitBtn button {
  width: 10rem;
}

.backBtn {
  margin-left: 3rem;
}
