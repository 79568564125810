.footer {
  color: white;
  text-align: center;
  line-height: 1.5;
  margin: auto;
  height: 30rem;
  background-color: #182843;
  margin-top: 3rem;
}

.footer h3 {
  color: #ece5c7;
  margin-bottom: 1rem;
  text-align: left;
}
.footer h4
{
    text-align: left;
    margin: 1rem auto 0 auto;
}

.footer img {
  width: 15rem;
  margin: 2rem auto 3rem auto;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

div a {
  color: white;
  text-decoration: none;
}

div ul,
div li {
  list-style: none;
  text-align: left;
}

div li:hover{
    text-decoration: underline;
}