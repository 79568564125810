.header-txt {
  z-index: 200;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  margin: auto;
  margin-bottom: 1rem;
  overflow: visible;
  transition: 0.2s linear;
  position: sticky;
  bottom: 0;
  height: 27rem;
  padding-bottom: 2rem;
}

.header-txt-header {
  position: fixed;
  top: 1.5rem;
  margin: auto;
  font-size: 3rem;
  width: 100vw;
  text-align: center;
  margin-bottom: 2rem;
  color: white;
  text-shadow: 3px 3px 3px #354259;
  transition: 0.2s linear;
}
.header-txt-header span {
  color: #354259;
  background-color: transparent;
  text-shadow: 2px 2px 2px white;
}

.scrollHeader {
  display: none;
}

.main-display {
  width: 100%;
  height: 27rem;
  position: fixed;
  top: 0;
  z-index: 50;
  box-shadow: 0 0 10px black;
  overflow: visible;
  transition: 0.2s linear;
}

.scrollMain {
  height: 27rem;
  top: -20rem;
}

.main-display video {
  z-index: -10;
  transition: 0.2s linear;
  width: 100%;
  height: 42rem;
  object-fit: cover;
  position: fixed;
  clip: rect(0rem, 100vw, 27rem, 0vw);
  top: 0rem;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
}

.main-display .scrollVideo {
  top: -20rem;
}

.scrollHeader,
.scrollMain,
.scrollVideo {
  transition: 0.2s linear;
}
