.nav {
  display: flex;
  justify-content: left;
  padding-left: 2rem;
  position: fixed;
  top: 27.05rem;
  width: 100%;

  transition: 0.2s linear;
  z-index: 40;
}

.scrollNav {
  top: 7.05rem;
  transition: 0.2s linear;
}
nav {
  width: 100%;
  display: flex;
  justify-content: left;
}

.anchor,
.active {
  width: 6.64rem;
  box-shadow: 0 0px 3px inset #354259;
  text-decoration: none;
  padding: 0.7rem;
  margin: 0 0.2rem;
  background-color: #354259;
  color: white;
  border-radius: 0px 0px 5px 5px;
  text-align: center;
}

.anchor:hover,
.anchor:active {
  background-color: #ece5c7;
  color: #354259;
}

.active {
  background-color: #ece5c7;
  color: #354259;
}
