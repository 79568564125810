.signOut{
    display: flex;
    justify-content: flex-start;
}

.delete{
    margin-top: 15rem
}

form img{
    margin-top: 1rem;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    object-fit: cover;
}

.userName{
    margin-top: 2rem;
}