
.signUpForm form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    width: 100%;
    color: #354259;
    
}

.signUpForm form div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: .2rem;
    width: 80%;
    
}

.signUpForm form label,
.signUpForm form input{
    width: 100%;
    padding: .16rem;
    border-radius: 3px;

}

.signUpForm form input{
    border-color: #354259;
}

.signUpForm form div:has(input[type='file']) {
    flex-direction: row;
}
