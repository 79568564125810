@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Public Sans", sans-serif;
  background-color: #f8f8f8;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  padding-top: 30rem;
  scroll-behavior: smooth;
}


