

h2 {
color: #354259;
}

h3 {
color: #354259;
text-align: center;
}

.activBox {
  display: flex;
  justify-content: center;
  gap: 0 2rem;
  margin: auto;
  color: #354259;
}

.activBox img {
  width: 10rem;
  height: 17rem;
  object-fit: cover;
}


.article {
  width: 30rem;
  height: 20.7rem;
  overflow: hidden;
  border-radius: 5px;
 transition: all .2s;
  box-shadow: 0 0 10px gray;
  position: relative;
 
}

.article a img{
  align-items: center;
  width: 100%;
}


.photoControl{
  width: 95%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 8.2rem;
  left: .5rem;
}


.article h3,
p {
  padding: 0.5rem;
}

.para {
  height: 8.9rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
}

.article:hover {
transform: translateY(-.2rem);

}
.topTitle {
  text-align: center;
  padding-bottom: 3rem;
}

/* Activity slides */

.slidesBtn{
  height: 2rem;
  margin: 9.2rem auto;
}

.picBtn{
  background-color: rgba(255, 255, 255, 0.294);
  color:white;
}
