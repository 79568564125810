h2 {
  text-align: center;
}

.offers {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.offersHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.offersHeader h2 {
  margin: 2rem;
  color: #354259;
}

.mainBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.singleOffer {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.205);
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  color: #354259;
}
.bookmarkIcon {
  align-self: flex-start;
  color: gray;
  margin-top: 1.2rem;
  width: 1.3rem;
  height: 1.3rem;
  background-color: transparent;
  pointer-events: none;
}

button:has(.bookmarkIcon) {
  background-color: transparent;
  align-self: flex-start;
}

button:has(.bookmarkIcon):hover::after {
  transform: scaleX(1.2) scaleY(1.4);
  background-color: #35425900;
  opacity: 0;
}

button:has(.bookmarkIcon):hover {
  transform: translateY(-0.2rem);
  box-shadow: none;
}

.bookMarked > .bookmarkIcon {
  color: gold;
}

.price {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  width: 12rem;
  height: 12.5rem;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.205);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  color: #354259;
}

.price h2 {
  font-weight: bold;
  font-size: 1.7rem;
}

.singleOffer > :first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
}

.itineraries {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 35rem;
  align-items: center;
}

.itineraries img {
  width: 5rem;
  height: 2.5rem;
  border-radius: 7px;
}

.itineraries > div {
  width: 19%;
  text-align: center;
}

.duration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: center;
}
.duration p {
  font-size: 0.7rem;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.duration p span {
  padding: 0 0 0 0.3rem;
  color: #354259;
}

.segments {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.hidden {
  display: none;
}

.singleSegment {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0.2rem;
  padding: 0.2rem;
  border: 1px solid rgba(0, 0, 0, 0.205);
}

.toggleButton {
  width: 3rem;
  cursor: pointer;
}

.timeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timeLine {
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  height: 1px;
  width: 6rem;
  color: #354259;
  background-color: #354259;
}

.redDot {
  border-radius: 180%;
  background-color: red;
  min-height: 9px;
  min-width: 9px;
  border: 2px solid white;
  margin: 4px;
}
.plane img {
  height: 1rem;
  width: 1rem;
  margin-top: 0.277rem;
  margin-left: 0.2rem;
  color: #354259;
}

.change {
  animation: moveInBottom 1.5s ease-out;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;

    background-color: #ece5c7;
  }

  100% {
    opacity: 1;

    background-color: #354259;
  }
}

.noBookmarks {
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  color: transparent;
  background-image: linear-gradient(
    to left,
    #b1b2ff,
    #aac4ff,
    #d2daff,
    #eef1ff
  );
  -webkit-background-clip: text;
}
