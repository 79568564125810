button {
  text-transform: uppercase;
  margin-top: 1rem;
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: #354259;
  color: white;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  transition: all .2s;
  position: relative;
  color: #ece5c7;
  animation: moveInBottom .5s ease-out;
}

button::after{
  content: "";
  display: inline-block;
  height: 100%;
  width:100%;
  border-radius: 12px;
   position: absolute;
  top: 0;
  left: 0; 
  z-index: -1;
  transition: all .4s;
}

button:hover::after {
  transform: scaleX(1.2) scaleY(1.4);
  background-color:  #354259;
  opacity: 0;
}

.submitButton {
  margin-top: 1.5rem;
  font-size: 2rem;
  padding: 0.5rem 1rem;
  background-color: #35425963;
}

button:hover{ 
  transform: translateY(-.2rem);
  box-shadow: 0 .3rem .5rem rgba(0,0,0,.4 ) ;
}

 button:active{
  transform: translateY(.2rem);
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.2 ) ;

} 
.user, .userOn{
  width: 5vw;
  font-size: .95rem;
  transition: 0.2s linear
}

.userOn {
  color:  #354259;
  background-color: #ece5c7; 
}
 
.registerOff,
.registerOn,
.loginOn,
.loginOff{
  background-color: #f8f8f8;
  box-shadow: 0 0 10px black;
  height: 27rem;
  width: 20vw;
  position: absolute;
  top: 0;
  transition: 0.3s linear;
}

.loginOn,
.settingsOn,
.registerOn {
  z-index: 100;
  right: 0;
}

.loginOff,
.settingsOff,
.registerOff {
  z-index: 100;
  right: -20vw;
}
.settingsOn,
.settingsOff{
  background-color: #f8f8f8;
  box-shadow: 0 0 10px black;
  height: 100vh;
  width: 20vw;
  position: absolute;
  top: 0;
  transition: 0.3s linear;
}

.burger{
  margin-right: 1rem;
  z-index: 200;
}

.bookmark{
  width: 8.6rem;
}
 
@keyframes moveInBottom {
  0% {
      opacity: 0;
      transform: translateY(3rem);
  }

  100% {
      opacity: 1;
      transform: translate(0);
  }
}

